.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 5.2rem 0.5rem 0.5rem 0.1rem;
    transition: margin-left $transitionDuration;
}

.layout-main {
    flex: 1 1 auto;
}
